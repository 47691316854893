import {$fetch} from "ofetch";
import {EventTypes} from "~/constants";

export default () => {

    async function logEvent(eventName: EventTypes, metadata: object) {
        $fetch("/api/log_event", {
            method: "POST",
            body: {
                event: eventName,
                metadata: metadata
            }
        });
    }

    return {
        logEvent: logEvent
    }

}
